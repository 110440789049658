import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import DynamicContent from "../components/DynamicContent";

import { getPage } from "ab-api-queries";

const Home = ({ content = [], moduloCross, _nextI18Next }) => {
  const { i18n } = useTranslation("common");
  console.log("homeContent", content);
  return (
    <>
      {content?.map((itm, index) => (
        <DynamicContent
          {...itm}
          _nextI18Next={_nextI18Next}
          key={`${itm.fieldGroupName}-${index}`}
          moduloCross={moduloCross}
          i18n={i18n}
        />
      ))}
    </>
  );
};

export async function getStaticProps({ locale }) {
  const data = await getPage("/");
  // const locale = getLocaleFromContext(context);
  // const redirectURL = getLinkFromTranslation(data?.nodeByUri?.translations, locale);

  // if (locale != 'es' && redirectURL) {
  //   return {
  //     redirect: {
  //       permanent: false,
  //       destination: redirectURL
  //     }
  //   };
  // }

  const translations = await serverSideTranslations(locale, ["common"]);

  console.log("RENDER INDEX");

  return {
    props: {
      seo: data?.nodeByUri?.seo || null,
      content: data?.nodeByUri?.contenidoFlexible?.modulos || [],
      footer: data?.headerFooter?.footer || null,
      menu: data?.headerFooter?.menu || null,
      translations: data?.nodeByUri?.translations || null,
      language: data?.nodeByUri?.language || null,
      moduloCross: data?.modulosCross || null,
      ...translations,
    }, // will be passed to the page component as props
    revalidate: process.env.REVALIDATE ? parseInt(process.env.REVALIDATE, 10) : 1800,
  };
}

export default Home;
